.AnswerComponent{
    margin-top: 15px;

    .form-group{
        margin: 0;
        
        .custom-checkbox,
        .custom-radio{
            display: inline;

            & ~ label {
                display: inline;

                > div {
                    display: inline-block;
                    width: 85%;
                }

            }
        }

        label{
            margin: 0;
        }

    }
}

.OneLineComponent{
    input{
        max-width: 350px;
        font-size: 14px;
        background: transparent;
        color: #000;
        border: none;
        border-bottom: 1px #000 solid;
        border-radius: 0;
    }
}

.DateFieldComponent,
.TimeFieldComponent{
    input{
        max-width: 200px;
        font-size: 14px;
        background: transparent;
        color: #000;
        border: none;
        border-bottom: 1px #000 solid;
        border-radius: 0;

    }
}

.ParagraphComponent {
    textarea {
        max-width: 600px;
        font-size: 14px;
        background: transparent;
        color: #000;
        border: none;
        border-bottom: 1px #000 solid;
        border-radius: 0;
    }
}